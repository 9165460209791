<template>
    <div class="sms_template_list">
        <el-card>
            <nav class="input" style="width: auto">
                <el-input v-model="search.keyword" size="medium" placeholder="请输入标题" />
            </nav>
            <nav class="input" style="width: 150px">
                <el-button size="small" @click="reset">重置</el-button>
                <el-button type="primary" size="small" @click="$refs.table.reload()">{{ "搜索" }}</el-button>
            </nav>
            <nav class="input" style="width: auto">
                <router-link to="/sms_template_list_add">
                    <el-button type="primary" size="small">{{ "创建模板" }}</el-button>
                </router-link>
            </nav>
        </el-card>
        <el-card style="margin-top: 10px">
            <z-table ref="table" v-model="list" @query="queryList" fullscreenLoading>
                <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
                    <el-table-column prop="id" label="ID"> </el-table-column>
                    <el-table-column prop="title" label="标题"> </el-table-column>
                    <el-table-column prop="templateId" label="模板ID"> </el-table-column>
                    <el-table-column prop="content" label="模板内容"> </el-table-column>
                    <el-table-column prop="remark" label="模板申请说明"> </el-table-column>
                    <el-table-column prop="status" label="状态">
                        <template slot-scope="{ row }">
                            <el-tag
                                size="small"
                                v-if="Ftag(row.status)"
                                :type="row.status == 1 ? '' : row.status == 2 ? 'danger' : 'info'"
                            >
                                {{ Ftag(row.status) }}
                            </el-tag>
                            <div v-if="row.status == 2">
                                {{ row.failReason }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="manager" label="创建人"> </el-table-column>
                    <el-table-column prop="remark" label="备注">
                        <template #default="{row}">
                            <Remark :row="row" url="/admin/Sms/editTemplateRemark" rowKey="managerRemark"></Remark>
                        </template>
                    </el-table-column>

                    <el-table-column prop="createTime" label="创建时间"> </el-table-column>

                    <el-table-column label="操作">
                        <template slot-scope="{ row }">
                            <el-button @click="onEdit(row)" type="text" icon="el-icon-edit" size="mini">编辑</el-button>
                            <el-button
                                @click="onDelete(row)"
                                type="text"
                                icon="el-icon-delete"
                                size="mini"
                                style="color: red;"
                                >删除</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </z-table>
        </el-card>
    </div>
</template>

<script>
import list from "/src/mixin/list.js";
export default {
    mixins: [list],
    data() {
        return {
            search: {
                keyword: "",
            },
        };
    },
    activated() {
        console.log(1);
    },
    methods: {
        getlist() {
            this.$refs.table.refresh();
        },

        async queryList(val) {
            let { data: res } = await this.$http.get("/admin/Sms/getTemplateList", {
                params: { ...val, ...this.search },
            });
            if (res.errorCode == 200) {
                // this.$message.success(res.message);
                this.$refs.table.complete(res.data);
            }
        },
        onEdit(row) {
            this.$router.push({ name: "sms_template_list_add", query: { id: row.id } });
        },
        async onDelete(row) {
            await this.$confirm("此操作将永久删除该, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            });
            let { data: res } = await this.$http.post("/admin/Sms/delTemplate", row);
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlist();
            }
        },
        Ftag(val) {
            if (val == 0) {
                return "待审核";
            }
            if (val == 1) {
                return "审核通过";
            }
            if (val == 2) {
                return "审核不通过";
            }
            return "";
        },
    },
};
</script>

<style lang="scss" scoped></style>
